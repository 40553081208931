import React, { useRef, useEffect, useState, useCallback } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import "./App.css";

function App() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [boxes, setBoxes] = useState([]);
  const isProcessing = useRef(false);
  const [useRearCamera, setUseRearCamera] = useState(true); // Quản lý trạng thái camera

  const [boxHistory, setBoxHistory] = useState([]); // Lưu lịch sử boxes (khoảng 5 frame gần nhất)
  const smoothingFactor = 5; // Số frame để tính trung bình
  const [stableBoxes, setStableBoxes] = useState([]);
  const disappearingTimeout = useRef(null);

  const updateBoxes = (newBoxes) => {
    if (newBoxes.length > 0) {
      clearTimeout(disappearingTimeout.current);
      setStableBoxes(newBoxes);
    } else {
      disappearingTimeout.current = setTimeout(() => {
        setStableBoxes([]);
      }, 300); // Giữ box trong 300ms nếu không phát hiện
    }
  };
  const smoothBoxes = (newBoxes) => {
    // Thêm box mới vào lịch sử
    const updatedHistory = [...boxHistory, newBoxes].slice(-smoothingFactor);
    setBoxHistory(updatedHistory);
  
    // Tính trung bình cho từng box
    const averagedBoxes = [];
    newBoxes.forEach((box, index) => {
      let sumX = 0, sumY = 0, sumWidth = 0, sumHeight = 0;
  
      updatedHistory.forEach((frame) => {
        if (frame[index]) {
          sumX += frame[index].x;
          sumY += frame[index].y;
          sumWidth += frame[index].width;
          sumHeight += frame[index].height;
        }
      });
  
      averagedBoxes.push({
        x: sumX / updatedHistory.length,
        y: sumY / updatedHistory.length,
        width: sumWidth / updatedHistory.length,
        height: sumHeight / updatedHistory.length,
        label: box.label,
        confidence: box.confidence,
      });
    });
  
    return averagedBoxes;
  };
  

  // Chụp ảnh từ camera và gửi đến backend
  const capture = useCallback(async () => {
    if (!webcamRef.current) return;

    const imageSrc = webcamRef.current.getScreenshot();
    if (!imageSrc) {
      console.error("Failed to capture image from webcam.");
      return;
    }
    isProcessing.current = true;
    try {
      const response = await axios.post("https://service.dxtech.vn:8710/predict", {
        image: imageSrc,
      });
      setBoxes(response.data.boxes || []);
    } catch (error) {
      console.error("Detection error:", error.response || error.message);
      setBoxes([]);
    } finally {
      isProcessing.current = false;
    }
  }, []);

  // Vẽ bounding boxes lên canvas
  // const drawCanvas = useCallback(() => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas?.getContext("2d");
  //   const video = webcamRef.current?.video;

  //   if (!canvas || !ctx || !video) {
  //     console.error("Canvas or webcam not ready.");
  //     return;
  //   }

  //   // Set kích thước canvas khớp với video
  //   const targetWidth = 640;
  //   const scaleFactor = targetWidth / video.videoWidth;
  //   canvas.width = targetWidth;
  //   canvas.height = video.videoHeight * scaleFactor;

  //   // Vẽ frame camera lên canvas
  //   ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  //   // Vẽ bounding boxes
  //   boxes.forEach(({ x, y, width, height, label, confidence }) => {
  //     ctx.strokeStyle = label === "fresh" ? "lime" : "red";
  //     ctx.lineWidth = 3;
  //     ctx.strokeRect(x, y, width, height);

  //     ctx.fillStyle = "lime";
  //     ctx.font = "14px Arial";
  //     ctx.fillText(
  //       `${label} (${confidence.toFixed(1)}%)`,
  //       x,
  //       y > 10 ? y - 5 : 10
  //     );
  //   });
  // }, [boxes]);
  // Vẽ bounding boxes lên canvas
  const drawCanvas = useCallback(async () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    const video = webcamRef.current?.video;
  
    if (!canvas || !ctx || !video) {
      console.error("Canvas or webcam not ready.");
      return;
    }
  
    // Kích thước video gốc
    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;
  
    // Đặt kích thước canvas khớp với video
    canvas.width = videoWidth;
    canvas.height = videoHeight;
  
    // Vẽ khung video lên canvas
    ctx.drawImage(video, 0, 0, videoWidth, videoHeight);
    console.log(video.videoWidth)
  
    // Lấy thông tin từ backend (thêm input_width và input_height)
    try {
      const response = await axios.post("https://service.dxtech.vn:8710/predict", {
        image: webcamRef.current.getScreenshot(),
      });
  
      const { boxes, input_width, input_height } = response.data;
  
      // Scale tọa độ bounding boxes theo tỷ lệ giữa YOLO và video
      const scaleX = videoWidth / input_width;
      const scaleY = videoHeight / input_height;
  
      // const smoothedBoxes = smoothBoxes(boxes);
      // Cập nhật box ổn định
      updateBoxes(boxes);
      
      // Vẽ bounding boxes
      boxes.forEach(({ x, y, width, height, label, confidence }) => {
      // smoothedBoxes.forEach(({ x, y, width, height, label, confidence }) => {
        const scaledX = x * scaleX;
        const scaledY = y * scaleY;
        const scaledWidth = width * scaleX;
        const scaledHeight = height * scaleY;
  
        // Vẽ khung bao
        ctx.strokeStyle = label === "thịt tươi" ? "lime" : "red";
        ctx.lineWidth = 3;
        ctx.strokeRect(scaledX, scaledY, scaledWidth, scaledHeight);
  
        // Vẽ nhãn và độ tin cậy
        ctx.fillStyle = "lime";
        ctx.font = "14px Arial";
        ctx.fillText(
          `${label} (${confidence.toFixed(1)}%)`,
          scaledX,
          scaledY > 10 ? scaledY - 5 : 10
        );
      });
    } catch (error) {
      console.error("Error fetching prediction data:", error);
    }
  }, [boxes]);
  


  // Gửi frame đến backend mỗi 500ms và vẽ lên canvas
  useEffect(() => {
    const interval = setInterval(() => {
      capture();
      
      drawCanvas();
    }, 500);
    return () => clearInterval(interval);
  }, [capture, drawCanvas]);

  // Chuyển đổi trạng thái camera (trước/sau)
  const toggleCamera = () => {
    setUseRearCamera((prev) => !prev);
  };

  return (
    <div className="relative min-h-screen bg-gray-900 text-white flex flex-col items-center">
      {/* <header className="header" style={{ width: "250vw", textAlign: "center"}}>
        <div className="flex justify-center items-center">
          <h1 className="text-2xl font-extrabold text-white tracking-wide">
            🍖 Detect Pork
          </h1>
        </div>
      </header> */}

<header className="header bg-gray-800 text-white">
  <div className="flex justify-center items-center">
    <h1 className="text-2xl font-extrabold tracking-wide">🍖 Phát hiện loại thịt lợn </h1>
  </div>
</header>


      <div className="relative mt-4 w-full">
        {/* Webcam */}
        <Webcam
          ref={webcamRef}
          audio={false}
          screenshotFormat="image/jpeg"
          videoConstraints={{
            facingMode: useRearCamera ? "environment" : "user",
          }}
          style={{ visibility: "hidden", position: "absolute" }}
        />
        {/* Canvas */}
        <canvas
          ref={canvasRef}
          className="w-full h-auto rounded-md shadow-md"
          aria-label="Detection result"
        />
      </div>
      <button
       onClick={toggleCamera}
       className="px-6 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold rounded-full shadow-lg hover:from-blue-600 hover:to-blue-700 transition-transform transform hover:scale-105 active:scale-95"
       style={{
         display: "inline-flex",
         alignItems: "center",
         justifyContent: "center",
         gap: "8px",}}
      >
        🔄 Chuyển đổi camera
      </button>
      {/* <footer className="footer"  style={{ width: "250vw", textAlign: "center" }}>
        <div className="main-content">
          <p className="text-sm mb-2">Powered by AI | Developed with ❤️</p>
          <div className="footer .links a">
            <a
              href="#"
              className="text-yellow-400 hover:text-yellow-500 text-sm transition duration-300"
            >
              Privacy Policy
            </a>
            <span className="footer .links a">|</span>
            <a
              href="#"
              className="text-yellow-400 hover:text-yellow-500 text-sm transition duration-300"
            >
              Terms of Service
            </a>
          </div>
        </div>
      </footer> */}
      <footer className="footer bg-gray-800 text-white"  style={{height: "300vw"}}>
  <div>
    <p className="text-sm mb-2"> Developed with Quynh Anh & Tuong Vy ❤️</p>
    <div>
      <a
        href="0399304907"
        className="text-yellow-400 hover:text-yellow-500 text-sm transition duration-300"
      >
        Liên hệ
      </a>
    </div>
  </div>
</footer>
    </div>
  );
}

export default App;
